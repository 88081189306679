import React, { Component, useCallback, useState } from "react";
import { withAdminLayout } from "../../layout";
import { withAppContext } from "../../components/withAppContext";
import { adminApiLogfile, adminApiUserUpdate } from "../../util/adminApi";
import { AdminUserTable } from "../../components/AdminUserTable/Index";
import { FormattedMessage } from "react-intl";
import { impersonate } from "../../util/auth";

const ImpersonateUser = () => {
    const [username, setUsername] = useState("");

    const onSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            impersonate(username);

            return false;
        },
        [username],
    );

    return (
        <>
            <div
                style={{
                    minHeight: "50vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f8f9fa",
                    padding: "1.5rem",
                }}
            >
                <div
                    style={{
                        background: "white",
                        borderRadius: "0.5rem",
                        boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                        padding: "2rem",
                        width: "100%",
                        maxWidth: "500px",
                    }}
                >
                    <form onSubmit={onSubmit}>
                        <div style={{ marginBottom: "1rem !important" }}>
                            <label
                                htmlFor="username"
                                style={{ marginBottom: ".5rem" }}
                            >
                                Username
                            </label>
                            <input
                                type="text"
                                style={{
                                    display: "block",
                                    marginBlock: "1rem",
                                    width: "100%",
                                    padding: ".375rem .75rem",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    lineHeight: "1.5",
                                    color: "#212529",
                                    backgroundColor: "#fff",
                                    backgroundClip: "padding-box",
                                    border: "1px solid #ced4da",
                                    appearance: "none",
                                    borderRadius: ".25rem",
                                    transition:
                                        "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                                }}
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter username"
                            />
                        </div>
                        <button
                            type="submit"
                            style={{
                                color: "#fff",
                                backgroundColor: "#0d6efd",
                                borderColor: "#0d6efd",
                                width: "100%",
                                height: "100%",
                                display: "inline-block",
                                fontWeight: "400",
                                lineHeight: "1.5",
                                textAlign: "center",
                                textDecoration: "none",
                                verticalAlign: "middle",
                                cursor: "pointer",
                                userSelect: "none",
                                border: "1px solid transparent",
                                padding: ".375rem .75rem",
                                fontSize: "1rem",
                                borderRadius: ".25rem",
                                transition:
                                    "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                            }}
                        >
                            Impersonate
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

const customProps = {
    withAuth: true,
};

export default withAdminLayout(customProps)(withAppContext(ImpersonateUser));
